<template>
  <b-modal
    v-model="dialogState"
    size="lg"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    @show="resetForm"
  >
    <template v-slot:modal-title>
      <h5 class="mb-0 text-secondary">
        {{ $t("page.groups.newGroup") }}
      </h5>
    </template>
    <template v-slot:default>
      <b-alert
        :show="$_authUserIsDomi && activeAdmin === null"
        variant="warning"
        class="mb-1 py-1 px-2"
        v-html="$t('page.parameters.noAdminWarningMsg')"
      ></b-alert>
      <validation-observer ref="observer" v-slot="{ invalid }">
        <b-form>
          <form-input
            v-model="group.name"
            label="page.groups.createModal.nameLabel"
            :placeholder="$t('page.groups.createModal.namePlacehoder')"
            veeName="page.groups.createModal.nameLabel"
            :veeRules="{ required: true }"
          />
          <table class="mb-5">
            <tr>
              <td>
                <div class="mb-2 mr-3">
                  {{ $t("page.groups.createModal.comments") }}
                </div>
              </td>
              <td>
                <base-switcher
                  v-model="group.commentPermissions"
                  name="comments-switch"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div class="mb-2 mr-3">
                  {{ $t("page.groups.createModal.status") }}
                </div>
              </td>
              <td>
                <base-switcher v-model="group.status" name="status-switch" />
              </td>
            </tr>
          </table>

          <hr class="my-3 mx-n3" />
          <b-row class="no-gutters justify-content-end">
            <b-button variant="outline-dark" class="mr-3" @click="closeModal">
              {{ $t("button.cancel") }}
            </b-button>
            <b-button
              class="text-white"
              variant="primary"
              :disabled="invalid || ($_authUserIsDomi && activeAdmin === null)"
              @click="createGroup"
            >
              {{ $t("button.insert") }}
            </b-button>
          </b-row>
        </b-form>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import FormInput from "../Base/BaseFormInput";
import BaseSwitcher from "./../Base/Switcher.vue";

import { authMixin } from "./../../mixins";

export default {
  props: {
    state: { type: Boolean, default: false },
    callbackAfterCreate: { type: Function, default: () => {} }
  },
  components: { FormInput, BaseSwitcher },
  mixins: [authMixin],
  data() {
    return {
      group: {
        name: "",
        status: false,
        commentPermissions: false
      }
    };
  },
  computed: {
    dialogState: {
      get() {
        return this.state;
      },
      set() {
        this.closeModal();
      }
    },
    activeAdmin() {
      return this.$store.getters["filter/getAdministratorSelected"];
    }
  },
  methods: {
    resetForm() {
      this.group = {
        name: "",
        status: false,
        commentPermissions: false
      };
    },
    async createGroup() {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("groups/createGroup", {
          payload: this.getGroupPayload()
        });
        await this.callbackAfterCreate();
        this.closeModal();
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    getGroupPayload() {
      const groupDetails = {
        ownerId: this.$_authUserIsDomi
          ? this.activeAdmin._id
          : this.$store.getters["auth/getAdminInfo"]._id,
        name: this.group.name,
        status: this.group.status ? "active" : "inactive",
        commentPermissions: this.group.commentPermissions
      };
      return groupDetails;
    },
    closeModal() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.alert {
  border-left: 10px solid var(--yellow);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
