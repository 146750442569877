<template>
  <div>
    <h3 class="mb-5 text-secondary">{{ $t("page.groups.title") }}</h3>
    <groups-filters></groups-filters>
    <div class="mb-5">
      <b-row class="no-gutters justify-content-between align-items-center mb-3">
        <b-col class="col-12">
          <b-button
            class="float-right mb-3"
            size="md"
            variant="secondary"
            @click="openCreateGroupModal"
            v-b-modal.create-group-modal
          >
            {{ $t("page.groups.newGroup") }}
          </b-button>
        </b-col>
      </b-row>
      <infinite-scroll @load="retrieveGroups">
        <groups-table
          :groups="groups"
          :fields="fields"
          :sortBy.sync="sortBy"
          :sortDesc.sync="sortDesc"
          :retrieveDataCallback="retrieveGroups"
          no-local-sorting
        />
      </infinite-scroll>
      <create-group
        :state="createModalState"
        :callbackAfterCreate="retrieveGroups"
        @close="closeCreateGroupModal"
      />
    </div>
  </div>
</template>

<script>
import { EventBus } from "./../eventBus.js";

import GroupsFilters from "../components/Groups/GroupFilters.vue";
import GroupsTable from "../components/Groups/GroupsTable.vue";
import CreateGroup from "../components/Groups/CreateGroup.vue";
import InfiniteScroll from "./../components/Base/BaseInfiniteScroll";

export default {
  components: {
    GroupsFilters,
    GroupsTable,
    CreateGroup,
    InfiniteScroll
  },
  data() {
    return {
      createModalState: false,

      fields: [
        {
          key: "name",
          label: "name",
          sort: true,
          thStyle: { width: "30%" }
        },
        { key: "status", label: "status", thStyle: { width: "15%" } },
        {
          key: "commentPermissions",
          label: "comment",
          thStyle: { width: "15%" }
        },
        {
          key: "membersCount",
          label: "users",
          sort: true,
          thStyle: { width: "15%" }
        },
        { key: "details", label: "", thStyle: { width: "25%" } }
      ]
    };
  },
  mounted() {
    EventBus.$on("eventBusReloadGroups", () => this.retrieveGroups(true));
    this.retrieveGroups(true);
  },
  computed: {
    groups() {
      return this.$store.getters["groups/getGroups"];
    },
    sortBy: {
      get() {
        const sortBy = this.$store.getters["groups/getSortBy"];
        return this.fields.find(f => f.sortBy === sortBy)?.key || sortBy;
      },
      set(value) {
        const sortBy = this.fields.find(f => f.key === value)?.sortBy || value;
        this.$store.commit("groups/SET_SORT_BY", sortBy);
        this.retrieveGroups(true);
      }
    },
    sortDesc: {
      get() {
        return this.$store.getters["groups/getSortDesc"];
      },
      set(value) {
        this.$store.commit("groups/SET_SORT_DESC", value);
        this.retrieveGroups(true);
      }
    }
  },
  methods: {
    async retrieveGroups(initial) {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("groups/retrieveGroups", {
          hasPagination: true,
          initial
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    openCreateGroupModal() {
      this.createModalState = true;
    },
    closeCreateGroupModal() {
      this.createModalState = false;
    }
  },
  beforeDestroy() {
    EventBus.$off("eventBusReloadGroups");
  }
};
</script>

<style lang="scss" scoped></style>
